<template>
  <div class="search">
    <div class="search-box">
      日期: <el-date-picker v-model="search.date" type="daterange"
                            range-separator="To"
                            start-placeholder="起始"
                            value-format="YYYYMMDD"
                            end-placeholder="结束"
    ></el-date-picker>
    </div>
    <div class="search-box">
      ID: <el-input placeholder="请输入软件ID" v-model="search.id"></el-input>
    </div>
    <div class="search-box">
      软件名字: <el-input placeholder="请输入软件名字" v-model="search.game_name"></el-input>
    </div>
    <div class="search-box">
      分类: <el-select v-model="search.type">
      <el-option :value="0" label="全部"></el-option>
      <el-option v-for="item of types.filter((t)=>{return t.disable==1})" :key="item" :value="item.type" :label="item.name"></el-option>
    </el-select>
    </div>
    <div class="search-box">
      <el-button type="primary" size="medium" @click="getList">查询</el-button>
    </div>
  </div>
  <el-table :data="tableData">
    <el-table-column prop="date" label="日期"></el-table-column>
    <el-table-column prop="id" label="ID"></el-table-column>
    <el-table-column prop="type" label="分类"></el-table-column>
    <el-table-column prop="name" label="名字"></el-table-column>
    <el-table-column prop="click_count" label="点击量"></el-table-column>
  </el-table>
  <page :pager="pager" @query="getList()" />
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page.vue";
export default {
  name: "ad_community",
  components: {
    page
  },
  data() {
    return {
      tableData:[],
      pager:{page:1,rows:20,total:0},
      search:{
        type:0,
        id:"",
      },
      types:[
        {type:1,name:"首页"}
      ],
    };
  },
  mounted() {
    this.getTypes()
    this.getList();
  },
  methods: {
    getTypes(){
      httpClient("getTypeList").post({page:0,row:100}).then((res) => {
        if (res.code == 0){
          this.types = res.data.list
        }
      })
    },
    getList() {
      let param = this.search
      if (this.search.date  && this.search.date.length === 2){
        param.bdate = this.search.date[0]
        param.edate = this.search.date[1]
      }
      param.page = this.pager.page -1
      param.row = this.pager.rows
      httpClient("staticAppList").post(param).then((res) => {
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }
      })
    },
    getType(id){
      for(const t of this.types){
        if (t.type == id){
          return t.name
        }
      }
      return "未知分类:"+id
    },
  },
};
</script>